@media (max-width: 1000px) {
  .App {
    width:100%;
    padding:auto;
    margin:auto
  }

}
h1 {
  text-align: center;
  color:white
}
@media (min-width: 1000px) {
  .App {
    width:50%;
    padding:auto;
    margin:auto
  }

}

.camera {
  width:100%;

}

.App {
  background-color: black;
}
body {
  background-color: white;
}

p {
  color:white
}